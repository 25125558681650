import styled from "styled-components";
import React from "react";

export const FavoritesInnerWrapper = styled.div`
  user-select: none;  
  z-index:  ${props => props.zIndex};
  margin: 0;
  right: 20px;
  position: absolute;
  top: 350px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  ${props => props.background && "background-color:white"};  // overlays at entdecken if uncommented
  padding:20px 10px 20px 30px;
`;

export const FavoriteItem = styled.div`
  user-select: none;   
  height: 45px;
  width: 45px;
  border: 2px solid #d9d9d9;
  margin: 2.5px;
  font-size:2rem;
  color:#d9d9d9;
  display:grid;
  place-items: center;
`;

export const FavoriteImg = styled.img`
  z-index:10;
  pointer-events: none;
  user-select: none;   
  max-width: 100%; 
  &:hover{
    cursor: grab;
  }
`;

export const FavoritenLabel = styled.p`
  position:absolute;
  font-size: 1rem;
  font-family: sans-serif;
  text-transform: uppercase;
  transform: rotate(-90deg);
  margin-left:-52px;
  margin-top:30px;
  padding-bottom:10px;
  font-family: 'Avenir Next',Arial,Helvetica,sans-serif;
  font-weight: 500;
`;

const FavoritesItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  
`

export const FavoritesWrapper = ({children, zIndex, background, kioskMode}) => {
  return (
    <>  
      <FavoritesInnerWrapper zIndex={zIndex} background={background} kioskMode={kioskMode}>
        <FavoritenLabel>Favoriten</FavoritenLabel>
        <FavoritesItemsWrapper>
          {children}
        </FavoritesItemsWrapper>
      </FavoritesInnerWrapper>
    </>
  );
};

