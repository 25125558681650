
// You can delete this file if you're not using it

import React from 'react';
import Layout from './src/components/Layout'


export const wrapPageElement = ({element, props}) => {
  return (
    <Layout {...props}>
        {element}
    </Layout>
  )
}
