//const baseUrl = "https://storage.googleapis.com/dive-in-public-test/2021-09-22/"
const baseUrl = "https://calico.timbayern.de/dive-in-public-test/2021-09-22/"

const annotationDataUrl =`${baseUrl}data/annotation.json`
const colorWheelDataUrl = `${baseUrl}data/color_wheel.json`
const AIPositionDataUrl = `${baseUrl}data/ai_cloud.json`

const seedsEntryPointsData =`${baseUrl}data/entry-points-seeds.json`
const entryPointsNAK = `${baseUrl}data/entry-points-nak.json`

const generateVideo =`${baseUrl}video/latent-walk.mp4`


const fullPatternImageUrl = `${baseUrl}pattern/full/`

const croppedPatternImageUrl = `${baseUrl}pattern/crop/` 
const seedsThumbs = `${baseUrl}seeds/crop/64/`


const patternData = `${baseUrl}data/annotationConsolidated.json`


export { 
  annotationDataUrl,
  colorWheelDataUrl,
  AIPositionDataUrl,
  seedsEntryPointsData,
  seedsThumbs,
  generateVideo,
  fullPatternImageUrl,
  croppedPatternImageUrl,
  entryPointsNAK,
  patternData,
  baseUrl
}


