import styled from "styled-components";
import FavoritesContext from "../../context/favorites";
import React, { useContext } from "react";
import {FavoritesWrapper} from './FavoritesWrapper'
import {croppedPatternImageUrl} from "../../data/dataUrls"

export const FavoriteItem = styled.div`
  user-select: none;   
  height: 45px;
  width: 45px;
  border: 2px solid #d9d9d9;
  margin: 2.5px;
  font-size:2rem;
  color:#d9d9d9;
  display:grid;
  place-items: center;
`;

export const FavoriteImg = styled.img`
  z-index:10;
  pointer-events: none;
  user-select: none;   
  object-fit: cover;
  height: 100%;
  width: 100%;
  &:hover{
    cursor: grab;
  }
`;

export const FavoritenLabel = styled.p`
  font-size: 1rem;
  font-family: sans-serif;
  text-transform: uppercase;
  transform: rotate(-90deg);
  margin-left:-120px;
  padding-bottom:10px;
`;

const Dot = styled.div`
  font-size:2rem;
  color:#d9d9d9;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: #d9d9d9;
`

export const Favorites = () => {
  const { favorites } = useContext(FavoritesContext);
  return (
      <FavoritesWrapper zIndex={200} background={true}>
        {[...Array(12)].map((item, index) => 
          <FavoriteItem key ={index}>
            {favorites[index] ?
              <FavoriteImg
                draggable="false"
                src={`${favorites[index].generated ? "" : `${croppedPatternImageUrl}128/`}${favorites[index].path}`}
              />
              :
              <Dot/>
            }
          </FavoriteItem>
        )}
      </FavoritesWrapper>
  );
};

