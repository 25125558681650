import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"



const Wrapper = styled.div`
  margin-left:4vw;
  margin-top:5vh;
  z-index:9999;
  pointer-events: none;
  position:relative;
`
const NavWrapper = styled.nav`
  background: white;
  display:inline-flex;
  padding:5px 2px 5px 10px;
  > div{
    margin-right:30px;
  }
`

const NavItemText = styled(Link)`
  pointer-events: auto;
  display: inline-block;
  color:${props =>props.activepage  === 'true'? "black" :"#989898"};
  height:100%;
  font-weight: ${props =>props.activePage  === 'true' ? 700 : 500};
  font-family:'Avenir Next',sans-serif;
  font-size:min(42px,2.2vw);
  text-transform:uppercase;
  padding:0;
  text-decoration:none;
  letter-spacing:2.5px;
  &:not(:last-of-child) {
    
    }
  &:after{
    content:'';
    display:block;
    height: ${props =>props.activepage === 'true' ?  '0.36vw' : "0px"};
    width:100%;
    background-color:black;
    margin-top: -3px;
    border-radius: 20px;
  }
`




const Navigation = (props) => {
  const { currentPage} = props
 
  
  return (
    <Wrapper>
      <NavWrapper>
        <div ><NavItemText to={'/entdecken'} activepage={currentPage.includes('/entdecken' )  ? 'true' : 'false' }>Entdecken</NavItemText></div>
        <div ><NavItemText to={'/erleben'} activepage={currentPage === '/erleben' ? 'true' : 'false' }>Erleben</NavItemText></div>
        <div ><NavItemText to={'/entwerfen'} activepage={currentPage === '/entwerfen' ? 'true' : 'false' }>Entwerfen</NavItemText></div>
      </NavWrapper>
    </Wrapper>
  )

}

export default Navigation

