import React, {useState} from 'react'
import styled from 'styled-components'
import { Link }from 'gatsby'
import useKioskMode from "../hooks/useKioskMode"
const Wrapper = styled.div`
  z-index:500;
  position:absolute;
  height: 114px;
  width:130px;
  background-color: #fff;
  bottom:${props => props.kioskMode ? "70px" : "10px"};
  right:25px;
  padding:8px;
`

const Title = styled.div`
  font-family:'Avenir Next', sans-serif;
  letter-spacing: 1.2px;
  font-size:14px;
  font-weight:bold;
  >a{
    color:black;
    text-decoration:none;
  }
`

const LinkWrapper = styled.div`
  margin-top:10px;
  display:block;
  a, p {
    font: italic normal normal 10px/13px 'Avenir Next',sans-serif;
    letter-spacing: 1.19px;
    text-transform: uppercase;
    text-decoration: none;
    color:black;
    display: block;
    cursor:pointer;
  }
`

const PopupOverlay = styled.div`
  position:absolute;
  z-index: 10000;
  top:${props => props.show ? 0 : -200}%;
  height:100vh;
  width:100vw;
  background-color: #00000045;
  display:grid;
  place-items: center;
`

const PopupWrapper = styled.div`
  width:700px;
  height:800px;
  background-color:white;
  padding:30px;
  /* overflow:hidden; */
`

const TopWrapper = styled.div`
  width:100%;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;

  >h1{
    font-family:'Avenir Next',sans-serif;
    font-weight:bold;
    text-transform: uppercase;
    margin:0;
    line-height: 57px;
  }
`

const CloseButtonWrapper = styled.div`
  z-index:100;
  cursor:pointer;

  background-color:white;
  top:60px;
  right:20px;
  width:57px;
  height:57px;
  display:grid;
  place-items: center;
  font-size: 30px;
  >svg{
    width: 100%;
    height: 100%;
    stroke:black;
    stroke-width:2;
    &:hover{
      stroke-width:2.5;
      transform: scale(1.1);
    }
  }
`

const Content = styled.div`
  height:650px;
  overflow-y:scroll;
  h2{
    margin: 20px 0 10px 0;
  }
`

const PopUp = (props) => {
  const {setShowProjectInfo, show} = props; 
  const [kioskMode, setKioskMode] = useKioskMode();
  return (
    <PopupOverlay show={show}>
      <PopupWrapper>
        <TopWrapper>
          <h1>Calico</h1> 
          <CloseButtonWrapper onClick={() => setShowProjectInfo(false)}>  
            <svg viewBox="0 0 40 40">
              <path  d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </CloseButtonWrapper>
        </TopWrapper>
        <Content>
          <h2>Projektinfo</h2>
          <p>
            Im Staatlichen Textil- und Industriemuseum Augsburg (tim) lagert mit den über 550 textilen Musterbüchern ein einzigartiger Sammlungsbestand. Die Bücher stammen aus dem Nachlass der ehemaligen Neuen Augsburger Kattunfabrik (NAK) und bilden eine fast lückenlose Dokumentation der Firmengeschichte ab. Sie enthalten insgesamt weit über eine Million Stoffdruckmuster aus dem Zeitraum von 1792 bis 1996. Das Projekt „CALICO – entdecken. erleben. entwerfen“ erschließt historische Textilmuster der NAK neu und macht sie der Öffentlichkeit digital zugänglich.<br></br><br></br>
            Die interaktive Plattform stellt ausgewählte Muster aber nicht nur digital zur Verfügung, sondern erschafft mit Hilfe von Künstlicher Intelligenz (KI) noch nie dagewesene Musterentwürfe und lädt schließlich dazu ein, selbst kreativ zu werden. Aus den 3.000 historischen Mustern aus dem Sammlungsbestand erzeugt die eingesetzte KI über 75.000 neue Muster. Diese spiegeln das visuelle Erscheinungsbild des Ausgangmaterials wider, weil die KI so trainiert wurde, Charakteristika der Originale zu übernehmen und neu zu kombinieren. Die Muster präsentieren wir als fortlaufendes ineinander übergehende Animation. Die Nutzer:innen können die Animation zu jeder Zeit anhalten, die jeweiligen Muster genauer betrachten und neue Textil-Designs an unterschiedlichen Modesilhouetten ausprobieren und bei Bedarf herunterladen.<br></br><br></br>
            „CALICO – entdecken. erleben. entwerfen“ konnte im Rahmen von „dive in. Programm für digitale Interaktionen“ der Kulturstiftung des Bundes realisiert werden. Die Mittel dafür stammen aus dem Rettungs- und Zukunftspaket der Beauftragten der Bundesregierung für Kultur und Medien (BKM) im Programm <a target="_blank" href="https://www.kulturstiftung-des-bundes.de/de/projekte/erbe_und_vermittlung/detail/dive_in_programm_fuer_digitale_interaktionen.html">NEUSTART KULTUR</a>.
          </p>
          <h2>Bildrechte</h2>
          <p>
            Das Staatliche Textil- und Industriemuseum Augsburg (tim) stellt als öffentliche Institution die hier verwendeten und erzeugten Muster unter folgenden Bedingungen der Creative Commons zur Verfügung: <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode.de">CC-BY-NC-SA-4.0 </a>
            <br></br>BY (Attribution): Gib bei der Nutzung als Quelle das Staatliche Textil- und Industriemuseum Augsburg (tim) an.
            <br></br>NC (NonCommercial): Du kannst das Bildmaterial herunterladen, bearbeiten und für eigene private, nicht aber für kommerzielle Projekte nutzen.
            <br></br>SA (ShareAlike): Alle neu entstanden Werke, für die unsere Muster genutzt werden, sind der Allgemeinheit wieder zur Verbreitung, Benutzung und Veränderung unter den gleichen Lizenzbedingungen zur Verfügung zu stellen.
          </p>
          <h2>Projektbeteiligte</h2>
          <p>
            tim. Anneli Kraft (Projektleitung), Dr. Karl Borromäus Murr, Dr. Michaela Breil, Mariama De Brito, Katja Cox
            <br></br>projektpartner. <a href="https://labbinaer.de/" target="_blank" rel="noreferrer" > LAB BINÆR GBR. LAB FOR MEDIA ART</a>, <a href="https://www.maik-kern.de/" target="_blank" rel="noreferrer" >Maik Kern(Fotograf)</a> 
            <br></br>gefördert. dive in. Programm für digitale Interaktionen. <a href="https://www.kulturstiftung-des-bundes.de/de" target="_blank"  rel="noreferrer" >Kulturstiftung des Bundes</a>
          </p>
       </Content>
      </PopupWrapper>
    </PopupOverlay>
  )
}


const Footer = () => {
  const [showProjectInfo, setShowProjectInfo] = useState(false)
  const [kioskMode, setKioskMode] = useKioskMode();
  return (
  <>
    <Wrapper kioskMode={kioskMode}>
      <Title><Link to={"/"}>CALICO</Link></Title>
      <LinkWrapper>
        <p onClick={() => setShowProjectInfo(true)}>Projektinfo</p>
        <a href="https://www.timbayern.de/datenschutzerklaerung/" target="_blank" rel="noreferrer" >Datenschutz</a>
        <a href="https://www.timbayern.de/impressum/" target="_blank" rel="noreferrer" >Impressum</a>
      </LinkWrapper>
    </Wrapper>
    <PopUp show={showProjectInfo} setShowProjectInfo={setShowProjectInfo}/>
  </>
  )
}

export default Footer;

