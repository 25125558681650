module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-etracker-analytics/gatsby-browser.js'),
      options: {"plugins":[],"secureCode":"kxgidV"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"custom":[{"name":["Avenir Next Light","Avenir Next Demi","Avenir Next Bold"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
