// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-entdecken-closeup-js": () => import("./../../../src/pages/entdecken/closeup.js" /* webpackChunkName: "component---src-pages-entdecken-closeup-js" */),
  "component---src-pages-entdecken-js": () => import("./../../../src/pages/entdecken.js" /* webpackChunkName: "component---src-pages-entdecken-js" */),
  "component---src-pages-entwerfen-js": () => import("./../../../src/pages/entwerfen.js" /* webpackChunkName: "component---src-pages-entwerfen-js" */),
  "component---src-pages-erleben-js": () => import("./../../../src/pages/erleben.js" /* webpackChunkName: "component---src-pages-erleben-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

