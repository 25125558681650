import { timer as d3_timer } from 'd3-timer';
import { easePolyInOut as d3_easePolyInOut } from 'd3-ease';

export const Pattern = (texture, data, PIXI, onPatternClick) => {
  // create sprites from loaded images and put into allData
  // const texture = PIXI.Texture.from(url)
  const sprite = new PIXI.Sprite(texture)
  // bind data to pattern
  sprite.data = {
    ...data,
  }
  sprite.interactive = true;
  sprite.anchor.set(0.5)
  sprite.buttonMode = true;

  sprite.on('click', () => {
    if(!sprite.disableClick){ // to stop accidental click when dragging 
      onSpriteClick(sprite,data)
    }
  })

  sprite.moveEased = (newX,newY) => {

    const duration = 2000
    const delay = 0
    const oldX = sprite.x
    const oldY = sprite.y

    // distance
    const dX = newX - oldX
    const dY = newY - oldY
  
    // get position of element in row  https://medium.com/@eesur/d3-modulo-operation-to-create-a-grid-f47101831a
    sprite.x = newX
    sprite.y = newY

    let timer = d3_timer(function(elapsed) {
      let t = elapsed / duration;
      sprite.position.x =  oldX + (dX * d3_easePolyInOut(t, 3));
      sprite.position.y =  oldY + (dY * d3_easePolyInOut(t, 3));
      if(elapsed > duration) {
        timer.stop();
      }
    }, delay);

  }



  const onSpriteClick = (sprite) => {
    onPatternClick(sprite)
  }


  return sprite 
}
