import { useEffect, useState } from "react"
import { Pattern } from './pattern'
import {navigate} from 'gatsby'
import { patternData, baseUrl } from "../../data/dataUrls"



/*  const spriteSheetUrl = `${baseUrl}pattern/crop/spritesheet/jpg/64-0.json`
 const spriteSheetFileNames = ['64-0','64-1','64-2','64-3'] // neccessary because pixi doesnt do it automaticly
 */
const spriteSheetUrl = `${baseUrl}pattern/crop/spritesheet/jpg/128-0.json`
const spriteSheetFileNames = ['128-0','128-1','128-2','128-3','128-4','128-5','128-6','128-7','128-8','128-9','128-10','128-11','128-12'] // neccessary because pixi doesnt do it automaticly


const loadDataSpriteSheet = (data, loader, PIXI, onPatternClick, setAllData) => {
  // check https://pixijs.io/guides/basics/sprite-sheets.html
  // check https://pixijs.download/release/docs/PIXI.Loader.html
  if(loader.resources[spriteSheetFileNames[0]] !== undefined){
  } else {
    loader.add(spriteSheetFileNames[0],spriteSheetUrl).load((loader, resources) => {
      // make sprites for each texture and marry them with the annotation data
      let allDataTemp = spriteSheetFileNames
        .map((filename) =>
          Object.keys(resources[filename].textures) // make each key iteratible
            .map((texture) => {
              return Pattern(
                resources[filename].textures[texture],
                data.find((x) => x.mediaID === texture),
                PIXI,
                onPatternClick
              ); // add according data from annotations
            })
        )
        .flat() // flatten it so all patterns are in one big array
        .filter((pattern) => typeof pattern.data.date !== "undefined"); // filter out
        setAllData(allDataTemp)
    })
  }
} 


function onPatternClick(sprite){
  navigate(`/entdecken/closeup?pattern=${sprite.data.mediaID}`)
}


function usePixi() {
  const [PIXI, setPIXI] = useState(null)
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [allData, setAllData] = useState([])
  const [annotationData, setAnnotationData] = useState([])

  useEffect(() => {
    const PIXIinstance = require("pixi.js");
    setPIXI(PIXIinstance)
    const fetchData = async () => {
      const annotationDataRes = await fetch(patternData);
      const annotationData = await annotationDataRes.json();
      setAnnotationData(annotationData)
      if (annotationData) {
        const loader = PIXIinstance.Loader.shared;
        loader.onProgress.add((a) => setProgress(Math.round(a.progress))); // put progress in state to display it
        loadDataSpriteSheet(annotationData, loader, PIXIinstance, onPatternClick,setAllData); // load sprite sheet data and marry with annotation data
        loader.onComplete.add(() => {setLoading(false)}); // set loading flag false once its finished will trigger stuff in PixiContainer
      }
    };
    fetchData();
  }, [])
  return { PIXI, loading, allData, progress, annotationData }
}




export default usePixi